<template>
  <div>
    <div v-for="(ele, index) in imgList" :key="index" class="img-wrapper">
      <div class="img-inner-wrapper">
        <img
          :src="ele.url"
          alt=""
          class="img-self"
          @mouseover="hoverIndex = index"
          @mouseleave="hoverIndex = -1"
          :class="{ 'img-active': index === hoverIndex }"
        />
      </div>
      <div
        class="content"
        v-show="true"
        :class="{ 'current-active': index === hoverIndex }"
      >
        <Card style="width: 100%; margin: 0 auto">
          <div
            slot="title"
            style="
              text-align: left;
              padding: 0;
              margin: 0;
              width: 400px;
              position: relative;
              right: 130px;
            "
            :title="ele.content"
          >
            <p>
              <Icon type="ios-film-outline"></Icon>
              {{ ele.title }}
            </p>
            <p
              style="
                font-size: 8px;
                color: 999;
                padding-left: 130px;
                width: 500px;
                word-wrap: break-word;
                word-break: break-all;
                white-space: normal;
                overflow: visible;
              "
            >
              {{ ele.content }}
            </p>
          </div>
          <a href="#" slot="extra" @click="openD(ele)">
            <Icon type="ios-loop-strong"></Icon>
            历年详情>>
          </a>
          <ul>
            <li
              v-for="(c, id) in ele.children"
              :key="id"
              style="line-height: 25px; position: relative; right: 15px"
            >
              <a :href="c.url" target="_blank">{{ c.text }}</a>
              <span
                style="
                  color: #ffac2d;
                  float: right;
                  position: relative;
                  bottom: 2px;
                "
              >
                <!-- <Icon
                  type="ios-star"
                  v-for="n in 4"
                  :key="n"
                  style="width: 15px"
                ></Icon
                ><Icon type="ios-star" v-if="c.resultList.rate >= 9.5"></Icon
                ><Icon type="ios-star-half" v-else></Icon>
                {{ c.resultList.rate }} -->
        <Icon type="ios-checkmark" size="20" style="color:#5cb85c"></Icon>

              </span>
            </li>
          </ul>
        </Card>
      </div>
    </div>
    <Drawer
      placement="left"
      :width="1000"
      :closable="false"
      v-model="drawerSwitch"
    >
      <div slot="header">
        <p>{{ currentTitle }}</p>
      </div>
      <Tabs value="name1">
        <TabPane label="2020年" name="name1">
            <div>
                <p style="text-indent:32px;">{{currentContent}}</p>
            </div>
        </TabPane>
      </Tabs>
    </Drawer>
  </div>
</template>
<script>
const resultList = [
  {
    name: "1.检查结果",
    url: "",
    rate: 9.6,
  },
  {
    name: "2.检查结果",
    url: "",
    rate: 9.4,
  },
  {
    name: "3.检查结果",
    url: "",
    rate: 9.5,
  },
  {
    name: "4.检查结果",
    url: "",
    rate: 9.5,
  },
];
export default {
  name: "emergency",
  components: {},
  data() {
    return {
      hoverIndex: -1,
      drawerSwitch: false,
      currentTitle: "",
      currentContent:"",
      imgList: [
        {
          url: process.env.BASE_URL + "img/center/c1.jpg",
          title: "运行状态的连续监视和管理",
          content:
            "可24小时连续不断监视各线路的行车、客流、设备运行和主要故障、乘客视频信息；线网控制中心设施设备能够连续可靠运行；",
            result:"成都市轨道交通线网指挥中心（COCC）为中环控制中心，位于成都地铁运营管有限公司办公楼内，于2017年启用。COCC具备对行车、客流、设备运行和主要故障、乘客视频信息等进行24小时联系不断监视和管理的功能。",
          children: [
            { text: "行车监视", resultList: resultList },
            { text: "客流监视", resultList: resultList },
            { text: "主要设备运行监视", resultList: resultList },
            { text: "主要故障报警和处置情况监视", resultList: resultList },
            { text: "乘客视频图像监视", resultList: resultList },
          ],
          value: 0,
        },
        {
          url: process.env.BASE_URL + "img/center/c2.jpg",
          title: "线网级客流调控",
          content:
            "能够监测各线路的断面客流满载率，能够在客流超过承载能力时提供预警；",
            result:"COCC具备对行车、客流、设备运行和主要故障、乘客视频信息等进行24小时联系不断监视和管理的功能。",
          children: [
            { text: "断面客流满载率预警", resultList: resultList },
            { text: "车站承载能力预警", resultList: resultList },
            { text: "响应线路OCC的调控申请", resultList: resultList },
            { text: "降低换乘通道通过能力", resultList: resultList },
            { text: "临时关闭换乘通道", resultList: resultList },
            { text: "单项开放出入口", resultList: resultList },
            { text: "临时关闭出入口", resultList: resultList },
          ],
          value: 0,
        },
        {
          url: process.env.BASE_URL + "img/center/c3.jpg",
          title: "控制从换乘站进入故障线路的客流",
          content:
            "能够响应各线路控制中心的限流申请，对邻线相关车站或运营实施线网级客流控制措施（包括但不限于邻线换乘通道限流、邻线出入口限流、邻线上下行车错时进站、邻线列车在换乘站不停靠以及减少邻线列车上线等）",
          result:"COCC贯彻执行《线网调度管理规则》及相关应急预案，对于大客流管控实现“站控—线控—网控”三个级别的控制。",
          children: [
            { text: "各线路运营故障监视", resultList: resultList },
            { text: "响应线路OCC的限流申请", resultList: resultList },
            { text: "上下行列车错时进站", resultList: resultList },
            { text: "上下行列车不停站通过", resultList: resultList },
            { text: "调整发车间隔", resultList: resultList },
          ],
          value: 0,
        },
        {
          url: process.env.BASE_URL + "img/center/c4.jpg",
          title: "统筹乘客应急信息发布",
          content: "能够通过相关控制中心发布线网级客运协调和应急信息；",
          result:"地铁范围内的应急信息由COCC发布，遇发布不成功时，联系OCC发布，仍无效时通知车站操作。有轨电车范围内应急信息均由现代有轨电车分公司发布。",
          children: [
            { text: "线网级乘客应急信息发布", resultList: resultList },
            { text: "线网级乘客应急信息协调", resultList: resultList },
            { text: "授权线路OCC发布应急信息", resultList: resultList },
          ],
          value: 0,
        },
        {
          url: process.env.BASE_URL + "img/center/c5.jpg",
          title: "与各部门、单位的工作联络",
          content:
            "具有与城市交通运输、公安、气象、卫生、供电、通信等部门或单位的常态有效的联络工作机制，并具有应急演练机制。",
            result:"COCC负责与政府机构、相关企业、媒体建立日常沟通、联动机制。",
          children: [
            {
              text:
                "具有与交通、公安、气象、卫生、供电、通信、应急等部门或单位的联络工作机制",
              resultList: resultList,
            },
            { text: "联络工作机制运转有效", resultList: resultList },
          ],
          value: 0,
        },
      ],
    };
  },
  methods: {
    openD(value) {
      this.currentTitle = value.title;
      this.currentContent = value.result;
      this.drawerSwitch = true;
    },
  },
};
</script>
<style scoped>
.img-self {
  width: 600px;
  cursor: pointer;
}

.img-wrapper {
  border: 1px solid #993333;
  padding: 6px;
  margin: 12px;
  width: fit-content;
  display: flex;
}

.img-inner-wrapper {
  width: 600px;
  overflow: hidden;
}

.content {
  width: 600px;
  padding: 0 30px;
  font-size: 22px;
  text-indent: 44px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
@keyframes zt {
  0% {
    color: #aaa;
  }
  100% {
    color: #000;
  }
}
.current-active {
  animation: zt 2s ease 0s forwards;
}

@keyframes it {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

.img-active {
  animation: it 10s ease 0s forwards;
}

li {
  list-style-type: none;
}
</style>